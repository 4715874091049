import { UnitProcessing } from "@/until/option";
import {
  seriesBar, seriesLine,
  xAxis, yAxisDefaultUnit,
  yAxisRightUnit, yAxisTenThousandYuan,
} from "@/views/mainComponent/mainComponets/componets/echartConstSeting.ts";
import { toolFun } from "@/views/countryMonthReport/analysisReport/data";
import underscore from "underscore";

const carTypes = ["所有车型", "GS3Power", "GS4", "GS4PLUS", "GS8", "M6", "M8", "GA4", "GA6", "GA8", "影豹", "其他"];
const areaData = [
  { title: "平均单车销售水平事业毛利", kpiCodes: ["A0105072"], val: null },
  { title: "平均单车售后水平事业毛利", kpiCodes: ["B0105134"], val: null },
  { title: "销售水平事业毛利占销售业务毛利比", kpiCodes: ["A0107021"], val: null, isAvg: false },
];
const infoData = [
  { title: "销售水平事业毛利率", kpiCodes: ["A0105023"], val: 0, LastMonth: null, Unit: "", isAvg: false },
  { title: "平均单车销售水平事业收入", kpiCodes: ["A0104161"], val: 0, Unit: "", LastMonth: null },
  { title: "平均单车销售水平事业毛利", kpiCodes: ["A0105072"], val: 0, Unit: "", LastMonth: null },
  { title: "售后水平事业毛利率", kpiCodes: ["B0105108"], val: 0, LastMonth: null, Unit: "", isAvg: false },
  { title: "平均单车售后水平事业收入", kpiCodes: ["B0104227"], val: 0, Unit: "", LastMonth: null },
  { title: "平均单车售后水平事业毛利", kpiCodes: ["B0105134"], val: 0, Unit: "", LastMonth: null },
];

const columnsData = [
  {
    title: "指标名称",
    dataIndex: "name",
    key: "name", width: "10%",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "单位",
    dataIndex: "Unit",
    key: "Unit",
  },
  {
    title: "当月",
    dataIndex: "Current",
    key: "Current",
  },
  {
    title: "上月",
    dataIndex: "LastMonth",
    key: "LastMonth",
  },
  {
    title: "环比",
    dataIndex: "QOQ",
    key: "QOQ",
  },
  {
    title: "去年同期",
    dataIndex: "LastYear",
    key: "LastYear",
  },
  {
    title: "同比",
    dataIndex: "YOY",
    key: "YOY",
  },
  {
    title: "年度店均",
    dataIndex: "YearSum",
    key: "YearSum",
  },
];
const columnsDataAgv = [
  {
    title: "指标名称",
    dataIndex: "name",
    key: "name", width: "10%",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "单位",
    dataIndex: "Unit",
    key: "Unit",
  },
  {
    title: "当月",
    dataIndex: "Current",
    key: "Current",
  },
  {
    title: "上月",
    dataIndex: "LastMonth",
    key: "LastMonth",
  },
  {
    title: "环比",
    dataIndex: "QOQ",
    key: "QOQ",
  },
  {
    title: "去年同期",
    dataIndex: "LastYear",
    key: "LastYear",
  },
  {
    title: "同比",
    dataIndex: "YOY",
    key: "YOY",
  },
  {
    title: "年度平均",
    dataIndex: "NationAvg",
    key: "NationAvg",
  },
];
const tableData = {
  tableOne: {
    table: [
      {
        title: "销售水平事业收入", Unit: "万元",
        kpiCodes: ["A0103024", "A0103601", "A0103602", "A0103603", "A0103604", "A0103605", "A0103606", "A0103607", "A0103608", "A0103609", "A0103610", "A0103611"],
      },
      {
        title: "销售水平事业毛利", Unit: "万元",
        kpiCodes: ["A0104067", "A0104621", "A0104622", "A0104623", "A0104624", "A0104625", "A0104626", "A0104627", "A0104628", "A0104629", "A0104630", "A0104631"],
      },
      {
        title: "销售水平事业毛利率", YearSum: "-",
        kpiCodes: ["A0105023", "A0105121", "A0105122", "A0105123", "A0105124", "A0105125", "A0105126", "A0105127", "A0105128", "A0105129", "A0105130", "A0105131"],
      },
      {
        title: "单台水平事业收入", YearSum: "-",
        kpiCodes: ["A0104161", "A0104641", "A0104642", "A0104643", "A0104644", "A0104645", "A0104646", "A0104647", "A0104648", "A0104649", "A0104650", "A0104651"],
      },
      {
        title: "单台水平事业毛利", YearSum: "-",
        kpiCodes: ["A0105072", "A0105141", "A0105142", "A0105143", "A0105144", "A0105145", "A0105146", "A0105147", "A0105148", "A0105149", "A0105150", "A0105151"],
      },
    ],
    data: [],
  },
  tableOne_2: {
    table: [
      {
        title: "单台水平事业收入",
        kpiCodes: ["A0104161", "A0104641", "A0104642", "A0104643", "A0104644", "A0104645", "A0104646", "A0104647", "A0104648", "A0104649", "A0104650", "A0104651"],
      },
      {
        title: "单台水平事业毛利",
        kpiCodes: ["A0105072", "A0105141", "A0105142", "A0105143", "A0105144", "A0105145", "A0105146", "A0105147", "A0105148", "A0105149", "A0105150", "A0105151"],
      },
    ],
    data: [],
  },
  tableTwo: {
    table: [
      {
        title: "售后水平事业收入", Unit: "万元",
        kpiCodes: ["B0103099"],
      },
      {
        title: "售后水平事业毛利", Unit: "万元",
        kpiCodes: ["B0104187"],
      },
      {
        title: "售后水平事业毛利率", YearSum: "-",
        kpiCodes: ["B0105108"],
      },
    ],
    data: [],
  },
  tableThere: {
    table: [
      {
        title: "平均单车新车用品收入", YearSum: "-",
        kpiCodes: ["A0103068", "A0103200", "A0103201", "A0103202", "A0103203", "A0103204", "A0103205", "A0103206", "A0103207", "A0103208", "A0103209", "A0103210"],
      },
      {
        title: "平均单车新车用品毛利", YearSum: "-",
        kpiCodes: ["A0104115", "A0104223", "A0104224", "A0104225", "A0104226", "A0104227", "A0104228", "A0104229", "A0104230", "A0104231", "A0104232", "A0104233"],
      },
      {
        title: "新车用品毛利率", YearSum: "-",
        kpiCodes: ["A0104068", "A0104421", "A0104422", "A0104423", "A0104424", "A0104425", "A0104426", "A0104427", "A0104428", "A0104429", "A0104430", "A0104431"],
      },
      {
        title: "新车用品台次",
        kpiCodes: ["A0302026", "A0302045", "A0302046", "A0302047", "A0302048", "A0302049", "A0302050", "A0302051", "A0302052", "A0302053", "A0302054", "A0302055"],
      },
      {
        title: "新车用品渗透率", YearSum: "-",
        kpiCodes: ["A0303271", "A0304181", "A0304182", "A0304183", "A0304184", "A0304185", "A0304186", "A0304187", "A0304188", "A0304189", "A0304190", "A0304191"],
      },
    ],
    data: [],
  },
  tableFour: {
    table: [
      {
        title: "平均单车金融按揭收入", YearSum: "-",
        kpiCodes: ["A0103070", "A0103240", "A0103241", "A0103242", "A0103243", "A0103244", "A0103245", "A0103246", "A0103247", "A0103248", "A0103249", "A0103250"],
      },
      {
        title: "平均单车金融按揭毛利", YearSum: "-",
        kpiCodes: ["A0104117", "A0104263", "A0104264", "A0104265", "A0104266", "A0104267", "A0104268", "A0104269", "A0104270", "A0104271", "A0104272", "A0104273"],
      },
      {
        title: "金融按揭毛利率", YearSum: "-",
        kpiCodes: ["A0104070", "A0104461", "A0104462", "A0104463", "A0104464", "A0104465", "A0104466", "A0104467", "A0104468", "A0104469", "A0104470", "A0104471"],
      },
      {
        title: "金融按揭台次",
        kpiCodes: ["A0302028", "A0302085", "A0302086", "A0302087", "A0302088", "A0302089", "A0302090", "A0302091", "A0302092", "A0302093", "A0302094", "A0302095"],
      },
      {
        title: "金融按揭渗透率", YearSum: "-",
        kpiCodes: ["A0303273", "A0304221", "A0304222", "A0304223", "A0304224", "A0304225", "A0304226", "A0304227", "A0304228", "A0304229", "A0304230", "A0304231"],
      },
    ],
    data: [],
  },
  tableFive: {
    table: [
      {
        title: "平均单车新车保险收入", YearSum: "-",
        kpiCodes: ["A0103069", "A0103220", "A0103221", "A0103222", "A0103223", "A0103224", "A0103225", "A0103226", "A0103227", "A0103228", "A0103229", "A0103230"],
      },
      {
        title: "平均单车新车保险毛利", YearSum: "-",
        kpiCodes: ["A0104116", "A0104243", "A0104244", "A0104245", "A0104246", "A0104247", "A0104248", "A0104249", "A0104250", "A0104251", "A0104252", "A0104253"],
      },
      {
        title: "新车保险毛利率", YearSum: "-",
        kpiCodes: ["A0104069", "A0104441", "A0104442", "A0104443", "A0104444", "A0104445", "A0104446", "A0104447", "A0104448", "A0104449", "A0104450", "A0104451"],
      },
      {
        title: "新车保险台次",
        kpiCodes: ["A0302027", "A0302065", "A0302066", "A0302067", "A0302068", "A0302069", "A0302070", "A0302071", "A0302072", "A0302073", "A0302074", "A0302075"],
      },
      {
        title: "新车保险渗透率", YearSum: "-",
        kpiCodes: ["A0303272", "A0304201", "A0304202", "A0304203", "A0304204", "A0304205", "A0304206", "A0304207", "A0304208", "A0304209", "A0304210", "A0304211"],
      },
    ],
    data: [],
  },
  tableSix: {
    table: [
      {
        title: "平均单车上牌验车收入", YearSum: "-",
        kpiCodes: ["A0103071", "A0103520", "A0103521", "A0103522", "A0103523", "A0103524", "A0103525", "A0103526", "A0103527", "A0103528", "A0103529", "A0103530"],
      },
      {
        title: "平均单车上牌验车毛利", YearSum: "-",
        kpiCodes: ["A0104118", "A0104541", "A0104542", "A0104543", "A0104544", "A0104545", "A0104546", "A0104547", "A0104548", "A0104549", "A0104550", "A0104551"],
      },
      {
        title: "上牌验车毛利率", YearSum: "-",
        kpiCodes: ["A0104071", "A0104481", "A0104482", "A0104483", "A0104484", "A0104485", "A0104486", "A0104487", "A0104488", "A0104489", "A0104490", "A0104491"],
      },
      {
        title: "上牌验车台次",
        kpiCodes: ["A0302029", "A0302285", "A0302286", "A0302287", "A0302288", "A0302289", "A0302290", "A0302291", "A0302292", "A0302293", "A0302294", "A0302295"],
      },
      {
        title: "上牌验车渗透率", YearSum: "-",
        kpiCodes: ["A0303274", "A0304241", "A0304242", "A0304243", "A0304244", "A0304245", "A0304246", "A0304247", "A0304248", "A0304249", "A0304250", "A0304251"],
      },
    ],
    data: [],
  },
  tableSeven: {
    table: [
      {
        title: "平均单车新车延保收入", YearSum: "-",
        kpiCodes: ["A0103073", "A0103540", "A0103541", "A0103542", "A0103543", "A0103544", "A0103545", "A0103546", "A0103547", "A0103548", "A0103549", "A0103550"],
      },
      {
        title: "平均单车新车延保毛利", YearSum: "-",
        kpiCodes: ["A0104120", "A0104561", "A0104562", "A0104563", "A0104564", "A0104565", "A0104566", "A0104567", "A0104568", "A0104569", "A0104570", "A0104571"],
      },
      {
        title: "新车延保毛利率", YearSum: "-",
        kpiCodes: ["A0104073", "A0104501", "A0104502", "A0104503", "A0104504", "A0104505", "A0104506", "A0104507", "A0104508", "A0104509", "A0104510", "A0104511"],
      },
      {
        title: "新车延保台次",
        kpiCodes: ["A0302033", "A0302325", "A0302326", "A0302327", "A0302328", "A0302329", "A0302330", "A0302331", "A0302332", "A0302333", "A0302334", "A0302335"],
      },
      {
        title: "新车延保渗透率", YearSum: "-",
        kpiCodes: ["A0303276", "A0304261", "A0304262", "A0304263", "A0304264", "A0304265", "A0304266", "A0304267", "A0304268", "A0304269", "A0304270", "A0304271"],
      },
    ],
    data: [],
  },
  tableEight: {
    table: [
      {
        title: "平均单车销售自主服务项目收入", YearSum: "-",
        kpiCodes: ["A0103341", "A0103580", "A0103581", "A0103582", "A0103583", "A0103584", "A0103585", "A0103586", "A0103587", "A0103588", "A0103589", "A0103590"],
      },
      {
        title: "平均单车销售自主服务项目毛利", YearSum: "-",
        kpiCodes: ["A0104133", "A0104581", "A0104582", "A0104583", "A0104584", "A0104585", "A0104586", "A0104587", "A0104588", "A0104589", "A0104590", "A0104591"],
      },
      {
        title: "销售自主服务项目毛利率", YearSum: "-",
        kpiCodes: ["A0104281", "A0104521", "A0104522", "A0104523", "A0104524", "A0104525", "A0104526", "A0104527", "A0104528", "A0104529", "A0104530", "A0104531"],
      },
      {
        title: "销售自主服务项目台次",
        kpiCodes: ["A0302241", "A0302345", "A0302346", "A0302347", "A0302348", "A0302349", "A0302350", "A0302351", "A0302352", "A0302353", "A0302354", "A0302355"],
      },
      {
        title: "销售自主服务项目渗透率", YearSum: "-",
        kpiCodes: ["A0303277", "A0304301", "A0304302", "A0304303", "A0304304", "A0304305", "A0304306", "A0304307", "A0304308", "A0304309", "A0304310", "A0304311"],
      },
    ],
    data: [],
  },
  tableNine: {
    table: [
      {
        title: "平均单车置换返利",
        kpiCodes: ["A0104119"], YearSum: "-",
      },
      {
        title: "置换台次",
        kpiCodes: ["A0302030"],
      },
      {
        title: "置换率",
        kpiCodes: ["A0303275"], YearSum: "-",
      },
    ],
    data: [],
  },
  tableTen: {
    table: [
      {
        title: "平均新转续单车售后续保收入",
        kpiCodes: ["B0103184"], YearSum: "-",
      },
      {
        title: "平均新转续单车售后续保毛利",
        kpiCodes: ["B0104334"], YearSum: "-",
      },
      {
        title: "新转续售后续保毛利率",
        kpiCodes: ["B0104331"], YearSum: "-",
      },
      {
        title: "新转续售后续保台次",
        kpiCodes: ["B0302171"],
      },
      {
        title: "新转续售后续保渗透率",
        kpiCodes: ["B0303040"], YearSum: "-",
      },
      {
        title: "平均续转续单车售后续保收入",
        kpiCodes: ["B0103185"], YearSum: "-",
      },
      {
        title: "平均续转续单车售后续保毛利",
        kpiCodes: ["B0104335"], YearSum: "-",
      },
      {
        title: "续转续售后续保毛利率",
        kpiCodes: ["B0104332"], YearSum: "-",
      },
      {
        title: "续转续售后续保台次",
        kpiCodes: ["B0302172"],
      },
      {
        title: "续转续售后续保渗透率",
        kpiCodes: ["B0303041"], YearSum: "-",
      },
      {
        title: "平均非转续单车售后续保收入",
        kpiCodes: ["B0103186"], YearSum: "-",
      },
      {
        title: "平均非转续单车售后续保毛利",
        kpiCodes: ["B0104336"], YearSum: "-",
      },
      {
        title: "非转续售后续保毛利率",
        kpiCodes: ["B0104333"], YearSum: "-",
      },
      {
        title: "非转续售后续保台次",
        kpiCodes: ["B0302173"],
      },
      {
        title: "非转续售后续保渗透率",
        kpiCodes: ["B0303042"], YearSum: "-",
      },
    ],
    data: [],
  },
  tableEleven: {
    table: [
      {
        title: "平均单车非新车延保收入",
        kpiCodes: ["B0103119"], YearSum: "-",
      },
      {
        title: "平均单车非新车延保毛利",
        kpiCodes: ["B0104246"], YearSum: "-",
      },
      {
        title: "非新车延保毛利率",
        kpiCodes: ["B0104191"], YearSum: "-",
      },
      {
        title: "非新车延保台次",
        kpiCodes: ["B0302019"],
      },
      {
        title: "非新车延保渗透率",
        kpiCodes: ["B0303008"], YearSum: "-",
      },
    ],
    data: [],
  },
  tableTwelve: {
    table: [
      {
        title: "平均单车售后自主服务项目收入", YearSum: "-",
        kpiCodes: ["B0103125"],
      },
      {
        title: "平均单车售后自主服务项目毛利", YearSum: "-",
        kpiCodes: ["B0104250"],
      },
      {
        title: "售后自主服务项目毛利率", YearSum: "-",
        kpiCodes: ["B0104198"],
      },
      {
        title: "售后自主服务项目台次",
        kpiCodes: ["B0302075"],
      },
      {
        title: "售后自主服务项目渗透率", YearSum: "-",
        kpiCodes: ["B0303039"],
      },
    ],
    data: [],
  },
  tableThirteen: {
    table: [
      {
        title: "平均单车二手车零售毛利", YearSum: "-",
        kpiCodes: ["A0104603"],
      },
      {
        title: "平均单车二手车批售毛利", YearSum: "-",
        kpiCodes: ["A0104604"],
      },
      {
        title: "二手车零售占比", YearSum: "-",
        kpiCodes: ["A0304321"],
      },
      {
        title: "二手车批售占比", YearSum: "-",
        kpiCodes: ["A0304322"],
      },
    ],
    data: [],
  },
};

function seriesNumber(m, n) {
  let Arr = [];
  for (let i = 0; i < m; i++) {
    Arr.push(seriesBar);
  }
  for (let i = 0; i < n; i++) {
    Arr.push(seriesLine);
  }
  return Arr;
}

const chartData = {
  chartOne: {
    columns: ["日期", "新车用品毛利", "金融按揭毛利", "新车保险毛利", "上牌验车毛利", "新车延保毛利", "销售自主服务项目毛利", "置换毛利"],
    table: [
      {
        title: "新车用品毛利",
        kpiCodes: ["A0103026/A0104067", "A0103140/A0104621", "A0103141/A0104622", "A0103142/A0104623", "A0103143/A0104624", "A0103144/A0104625", "A0103145/A0104626", "A0103146/A0104627", "A0103147/A0104628", "A0103148/A0104629", "A0103149/A0104630", "A0103150/A0104631"],
      },
      {
        title: "金融按揭毛利",
        kpiCodes: ["A0103028/A0104067", "A0103180/A0104621", "A0103181/A0104622", "A0103182/A0104623", "A0103183/A0104624", "A0103184/A0104625", "A0103185/A0104626", "A0103186/A0104627", "A0103187/A0104628", "A0103188/A0104629", "A0103189/A0104630", "A0103190/A0104631"],
      },
      {
        title: "新车保险毛利",
        kpiCodes: ["A0103027/A0104067", "A0103160/A0104621", "A0103161/A0104622", "A0103162/A0104623", "A0103163/A0104624", "A0103164/A0104625", "A0103165/A0104626", "A0103166/A0104627", "A0103167/A0104628", "A0103168/A0104629", "A0103169/A0104630", "A0103170/A0104631"],
      },
      {
        title: "上牌验车毛利",
        kpiCodes: ["A0103029/A0104067", "A0103440/A0104621", "A0103441/A0104622", "A0103442/A0104623", "A0103443/A0104624", "A0103444/A0104625", "A0103445/A0104626", "A0103446/A0104627", "A0103447/A0104628", "A0103448/A0104629", "A0103449/A0104630", "A0103450/A0104631"],
      },
      {
        title: "新车延保毛利",
        kpiCodes: ["A0103031/A0104067", "A0103460/A0104621", "A0103461/A0104622", "A0103462/A0104623", "A0103463/A0104624", "A0103464/A0104625", "A0103465/A0104626", "A0103466/A0104627", "A0103467/A0104628", "A0103468/A0104629", "A0103469/A0104630", "A0103470/A0104631"],
      },
      {
        title: "销售自主服务项目毛利",
        kpiCodes: ["A0103350/A0104067", "A0103480/A0104621", "A0103481/A0104622", "A0103482/A0104623", "A0103483/A0104624", "A0103484/A0104625", "A0103485/A0104626", "A0103486/A0104627", "A0103487/A0104628", "A0103488/A0104629", "A0103489/A0104630", "A0103490/A0104631"],
      },
      {
        title: "置换毛利",
        kpiCodes: ["A0103030/A0104067", "A0102311/A0104621", "A0102312/A0104622", "A0102313/A0104623", "A0102314/A0104624", "A0102315/A0104625", "A0102316/A0104626", "A0102317/A0104627", "A0102318/A0104628", "A0102319/A0104629", "A0102320/A0104630", "A0102321/A0104631"],
      },
    ],
    settings: {
      type: "histogram",
      stack: { car: ["新车用品毛利", "金融按揭毛利", "新车保险毛利", "上牌验车毛利", "新车延保毛利", "销售自主服务项目毛利", "置换毛利"] },
      yAxisType: ["0.[0]%"],
      yAxisName: ["%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return UnitProcessing("%", params.data);
        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "%");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("%")],
      series: seriesNumber(7, 0),
    },
  },
  chartTwo: {
    columns: ["日期", "售后续保毛利", "非新车延保毛利", "售后自主服务项目毛利"],
    table: [
      {
        title: "售后续保毛利",
        kpiCodes: ["B0103108"],
      },
      {
        title: "非新车延保毛利",
        kpiCodes: ["B0103106"],
      },
      {
        title: "售后自主服务项目毛利",
        kpiCodes: ["B0103113"],
      },
    ],
    settings: {
      type: "histogram",
      stack: { car: ["售后续保毛利", "非新车延保毛利", "售后自主服务项目毛利"] },
      yAxisType: ["0"],
      yAxisName: ["元"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return UnitProcessing("元", params.data);
        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元")],
      series: seriesNumber(3, 0),
    },
  },
  chartThere: {
    columns: ["日期", "新车用品单车毛利", "新车用品渗透率", "新车用品毛利率"],
    table: [
      {
        title: "新车用品单车毛利",
        kpiCodes: ["A0104115", "A0104223", "A0104224", "A0104225", "A0104226", "A0104227", "A0104228", "A0104229", "A0104230", "A0104231", "A0104232", "A0104233"],
      },
      {
        title: "新车用品毛利率",
        kpiCodes: ["A0104068", "A0104421", "A0104422", "A0104423", "A0104424", "A0104425", "A0104426", "A0104427", "A0104428", "A0104429", "A0104430", "A0104431"],
      },
      {
        title: "新车用品渗透率",
        kpiCodes: ["A0303271", "A0304181", "A0304182", "A0304183", "A0304184", "A0304185", "A0304186", "A0304187", "A0304188", "A0304189", "A0304190", "A0304191"],
      },
    ],
    settings: {
      type: "histogram",
      showLine: ["新车用品渗透率", "新车用品毛利率"],
      axisSite: { right: ["新车用品渗透率", "新车用品毛利率"] },
      yAxisType: ["0.[0]", "0.[0]%"],
      yAxisName: ["元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          if(params.componentSubType === "line") {
            return UnitProcessing("%", params.data);
          } else {
            return UnitProcessing("元", params.data);
          }

        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: seriesNumber(1, 2),
    },
  },
  chartFour: {
    columns: ["日期", "金融按揭单车毛利", "金融按揭渗透率", "金融按揭毛利率"],
    table: [
      {
        title: "金融按揭单车毛利",
        kpiCodes: ["A0104117", "A0104263", "A0104264", "A0104265", "A0104266", "A0104267", "A0104268", "A0104269", "A0104270", "A0104271", "A0104272", "A0104273"],
      },
      {
        title: "金融按揭渗透率",
        kpiCodes: ["A0303273", "A0304221", "A0304222", "A0304223", "A0304224", "A0304225", "A0304226", "A0304227", "A0304228", "A0304229", "A0304230", "A0304231"],
      },
      {
        title: "金融按揭毛利率",
        kpiCodes: ["A0104070", "A0104461", "A0104462", "A0104463", "A0104464", "A0104465", "A0104466", "A0104467", "A0104468", "A0104469", "A0104470", "A0104471"],
      },
    ],
    settings: {
      type: "histogram",
      showLine: ["金融按揭渗透率", "金融按揭毛利率"],
      axisSite: { right: ["金融按揭渗透率", "金融按揭毛利率"] },
      yAxisType: ["0.[0]", "0.[0]%"],
      yAxisName: ["元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          if(params.componentSubType === "line") {
            return UnitProcessing("%", params.data);
          } else {
            return UnitProcessing("元", params.data);
          }

        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: seriesNumber(1, 2),
    },
  },
  chartFive: {
    columns: ["日期", "新车保险单车毛利", "新车保险渗透率", "新车保险毛利率"],
    table: [
      {
        title: "新车保险单车毛利",
        kpiCodes: ["A0104116", "A0104243", "A0104244", "A0104245", "A0104246", "A0104247", "A0104248", "A0104249", "A0104250", "A0104251", "A0104252", "A0104253"],
      },
      {
        title: "新车保险渗透率",
        kpiCodes: ["A0303272", "A0304201", "A0304202", "A0304203", "A0304204", "A0304205", "A0304206", "A0304207", "A0304208", "A0304209", "A0304210", "A0304211"],
      },
      {
        title: "新车保险毛利率",
        kpiCodes: ["A0104069", "A0104441", "A0104442", "A0104443", "A0104444", "A0104445", "A0104446", "A0104447", "A0104448", "A0104449", "A0104450", "A0104451"],
      },
    ],
    settings: {
      type: "histogram",
      showLine: ["新车保险渗透率", "新车保险毛利率"],
      axisSite: { right: ["新车保险渗透率", "新车保险毛利率"] },
      yAxisType: ["0.[0]", "0.[0]%"],
      yAxisName: ["元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          if(params.componentSubType === "line") {
            return UnitProcessing("%", params.data);
          } else {
            return UnitProcessing("元", params.data);
          }
        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: seriesNumber(1, 2),
    },
  },
  chartSix: {
    columns: ["日期", "上牌验车单车毛利", "上牌验车渗透率", "上牌验车毛利率"],
    table: [
      {
        title: "上牌验车单车毛利",
        kpiCodes: ["A0104118", "A0104541", "A0104542", "A0104543", "A0104544", "A0104545", "A0104546", "A0104547", "A0104548", "A0104549", "A0104550", "A0104551"],
      },
      {
        title: "上牌验车渗透率",
        kpiCodes: ["A0303274", "A0304241", "A0304242", "A0304243", "A0304244", "A0304245", "A0304246", "A0304247", "A0304248", "A0304249", "A0304250", "A0304251"],
      },
      {
        title: "上牌验车毛利率",
        kpiCodes: ["A0104071", "A0104481", "A0104482", "A0104483", "A0104484", "A0104485", "A0104486", "A0104487", "A0104488", "A0104489", "A0104490", "A0104491"],
      },
    ],
    settings: {
      type: "histogram",
      showLine: ["上牌验车渗透率", "上牌验车毛利率"],
      axisSite: { right: ["上牌验车渗透率", "上牌验车毛利率"] },
      yAxisType: ["0.[0]", "0.[0]%"],
      yAxisName: ["元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          if(params.componentSubType === "line") {
            return UnitProcessing("%", params.data);
          } else {
            return UnitProcessing("元", params.data);
          }
        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: seriesNumber(1, 2),
    },
  },
  chartSeven: {
    columns: ["日期", "新车延保单车毛利", "新车延保渗透率", "新车延保毛利率"],
    table: [
      {
        title: "新车延保单车毛利",
        kpiCodes: ["A0104120", "A0104561", "A0104562", "A0104563", "A0104564", "A0104565", "A0104566", "A0104567", "A0104568", "A0104569", "A0104570", "A0104571"],
      },
      {
        title: "新车延保渗透率",
        kpiCodes: ["A0303276", "A0304261", "A0304262", "A0304263", "A0304264", "A0304265", "A0304266", "A0304267", "A0304268", "A0304269", "A0304270", "A0304271"],
      },
      {
        title: "新车延保毛利率",
        kpiCodes: ["A0104073", "A0104501", "A0104502", "A0104503", "A0104504", "A0104505", "A0104506", "A0104507", "A0104508", "A0104509", "A0104510", "A0104511"],
      },
    ],
    settings: {
      type: "histogram",
      showLine: ["新车延保渗透率", "新车延保毛利率"],
      axisSite: { right: ["新车延保渗透率", "新车延保毛利率"] },
      yAxisType: ["0.[0]", "0.[0]%"],
      yAxisName: ["元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          if(params.componentSubType === "line") {
            return UnitProcessing("%", params.data);
          } else {
            return UnitProcessing("元", params.data);
          }
        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: seriesNumber(1, 2),
    },
  },
  chartEight: {
    columns: ["日期", "销售自主服务项目单车毛利", "销售自主服务项目渗透率", "销售自主服务项目毛利率"],
    table: [
      {
        title: "销售自主服务项目单车毛利",
        kpiCodes: ["A0104133", "A0104581", "A0104582", "A0104583", "A0104584", "A0104585", "A0104586", "A0104587", "A0104588", "A0104589", "A0104590", "A0104591"],
      },
      {
        title: "销售自主服务项目渗透率",
        kpiCodes: ["A0303277", "A0304301", "A0304302", "A0304303", "A0304304", "A0304305", "A0304306", "A0304307", "A0304308", "A0304309", "A0304310", "A0304311"],
      },
      {
        title: "销售自主服务项目毛利率",
        kpiCodes: ["A0104281", "A0104521", "A0104522", "A0104523", "A0104524", "A0104525", "A0104526", "A0104527", "A0104528", "A0104529", "A0104530", "A0104531"],
      },
    ],
    settings: {
      type: "histogram",
      showLine: ["销售自主服务项目渗透率", "销售自主服务项目毛利率"],
      axisSite: { right: ["销售自主服务项目渗透率", "销售自主服务项目毛利率"] },
      yAxisType: ["0.[0]", "0.[0]%"],
      yAxisName: ["元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          if(params.componentSubType === "line") {
            return UnitProcessing("%", params.data);
          } else {
            return UnitProcessing("元", params.data);
          }
        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: seriesNumber(1, 2),
    },
  },
  chartNine: {
    columns: ["日期", "平均单车置换返利", "置换率"],
    table: [
      {
        title: "平均单车置换返利",
        kpiCodes: ["A0104119"],
      },
      {
        title: "置换率",
        kpiCodes: ["A0303275"],
      },
    ],
    settings: {
      type: "histogram",
      showLine: ["置换率"],
      axisSite: { right: ["置换率"] },
      yAxisType: ["0.[0]", "0.[0]%"],
      yAxisName: ["元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          if(params.componentSubType === "line") {
            return UnitProcessing("%", params.data);
          } else {
            return UnitProcessing("元", params.data);
          }
        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: seriesNumber(1, 1),
    },
  },
  chartTen: {
    columns: ["日期", "新转续渗透率", "续转续渗透率", "非转续渗透率"],
    table: [
      {
        title: "新转续渗透率",
        kpiCodes: ["B0303040"], isAvg: false,
      },
      {
        title: "续转续渗透率",
        kpiCodes: ["B0303041"], isAvg: false,
      },
      {
        title: "非转续渗透率",
        kpiCodes: ["B0303042"], isAvg: false,
      },
    ],
    settings: {
      type: "histogram",
      showLine: ["新转续渗透率", "续转续渗透率", "非转续渗透率"],
      yAxisType: ["0.[0]%"],
      yAxisName: ["%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          return UnitProcessing("%", params.data);
        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "%");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("%")],
      series: seriesNumber(0, 3),
    },
  },
  chartEleven: {
    columns: ["日期", "非新车延保单车毛利", "非新车延保渗透率", "非新车延保毛利率"],
    table: [
      {
        title: "非新车延保单车毛利",
        kpiCodes: ["B0104246"],
      },
      {
        title: "非新车延保渗透率",
        kpiCodes: ["B0303008"],
      },
      {
        title: "非新车延保毛利率",
        kpiCodes: ["B0104191"],
      },
    ],
    settings: {
      type: "histogram",
      showLine: ["非新车延保渗透率", "非新车延保毛利率"],
      axisSite: { right: ["非新车延保渗透率", "非新车延保毛利率"] },
      yAxisType: ["0.[0]", "0.[0]%"],
      yAxisName: ["元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          if(params.componentSubType === "line") {
            return UnitProcessing("%", params.data);
          } else {
            return UnitProcessing("元", params.data);
          }
        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: seriesNumber(1, 2),
    },
  },
  chartTwelve: {
    columns: ["日期", "售后自主服务项目单车毛利", "售后自主服务项目渗透率", "售后自主服务项目毛利率"],
    table: [
      {
        title: "售后自主服务项目单车毛利",
        kpiCodes: ["B0104250"],
      },
      {
        title: "售后自主服务项目渗透率",
        kpiCodes: ["B0303039"],
      },
      {
        title: "售后自主服务项目毛利率",
        kpiCodes: ["B0104198"],
      },
    ],
    settings: {
      type: "histogram",
      showLine: ["售后自主服务项目渗透率", "售后自主服务项目毛利率"],
      axisSite: { right: ["售后自主服务项目渗透率", "售后自主服务项目毛利率"] },
      yAxisType: ["0.[0]", "0.[0]%"],
      yAxisName: ["元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          if(params.componentSubType === "line") {
            return UnitProcessing("%", params.data);
          } else {
            return UnitProcessing("元", params.data);
          }
        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: seriesNumber(1, 2),
    },
  },
  chartThirteen: {
    columns: ["日期", "平均单车二手车零售毛利", "平均单车二手车批售毛利", "二手车零售占比", "二手车批售占比"],
    table: [
      {
        title: "二手车零售占比",
        kpiCodes: ["A0304321"],
      },
      {
        title: "二手车批售占比",
        kpiCodes: ["A0304322"],
      },
      {
        title: "平均单车二手车零售毛利",
        kpiCodes: ["A0104603"],
      },
      {
        title: "平均单车二手车批售毛利",
        kpiCodes: ["A0104604"],
      },
    ],
    settings: {
      type: "histogram",
      stack: { car: ["平均单车二手车零售毛利", "平均单车二手车批售毛利"] },
      showLine: ["二手车零售占比", "二手车批售占比"],
      axisSite: { right: ["二手车零售占比", "二手车批售占比"] },
      yAxisType: ["0.[0]", "0.[0]%"],
      yAxisName: ["元", "%"],
      label: {
        show: true, // 显示数字
        position: "inside",
        textStyle: {
          color: "black",
        },
        formatter: function(params) {
          if(params.componentSubType === "line") {
            return UnitProcessing("%", params.data);
          } else {
            return UnitProcessing("元", params.data);
          }
        },
      },
    },
    rows: [],
    options: {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //   type: "shadow",        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(params) {
          return toolFun(params, "元");
        },
      },
      xAxis,
      yAxis: [yAxisDefaultUnit("元"), yAxisRightUnit("%")],
      series: seriesNumber(2, 2),
    },
  },
};

/*
* 传入数组,得到KpiCode数组
* */
function getKpiCode(arrData) {
  let arr = [];
  arrData.forEach(data => {
    data.kpiCodes.forEach(kpiCode => {
      if(data["isAvg"] !== undefined) {
        arr.push(kpiCode);// 要求不取平均数
      } else {
        arr.push(kpiCode + "_avg");
      }
    });
  });
  return arr;
}

function getTableDataCode(Data) {
  let arr = [];
  Object.values(Data).forEach(table => {
    table.table.forEach(kpi => {
      kpi.kpiCodes.forEach(kpiCode => {
        if(kpi["isAvg"] !== undefined) {
          arr.push(kpiCode);// 要求不取平均数
        } else {
          arr.push(kpiCode + "_avg");
        }
      });
    });
  });
  return arr;
}

const filterCodes = [...getKpiCode(areaData), ...getKpiCode(infoData), ...getTableDataCode(tableData)];

const filterChartCodes = [...getTableDataCode(chartData)];

/*
* 取平均反解析 data 后端数据, index 第几个CODE
* */
function isAddAvg(data, index) {
  if(data["isAvg"] !== undefined) {
    return data.kpiCodes[index];
  } else {
    return data.kpiCodes[index] + "_avg";
  }
}

function getUnitTableData(paramData, carSelect, tableName) {
  tableName.data = [];
  tableName.table.forEach(tableValue => {
    let Arr = {};
    Arr.name = tableValue.title;
    let index = carSelect.carKey;
    if(tableValue.kpiCodes.length < 5) index = 0; // 处理无车型选
    let kpiCode = isAddAvg(tableValue, index);
    Object.keys(paramData).forEach(d => {
      if(d === kpiCode) {
        let myData = paramData[kpiCode];
        if(tableValue.Unit !== undefined) {
          Arr.Unit = tableValue.Unit;
        } else {
          Arr.Unit = myData.Unit;
        }
        Arr.Current = UnitProcessing(Arr.Unit, myData.Current || 0);
        Arr.LastMonth = UnitProcessing(Arr.Unit, myData.LastMonth || 0);
        Arr.LastYear = UnitProcessing(Arr.Unit, myData.LastYear || 0);
        Arr.QOQ = UnitProcessing(Arr.Unit, myData.QOQ || 0);
        Arr.YOY = UnitProcessing(Arr.Unit, myData.YOY || 0);
        Arr.YearSum = UnitProcessing(Arr.Unit, myData.YearSum || 0);
        Arr.NationAvg = UnitProcessing(Arr.Unit, myData.NationAvg || 0);
      }
    });
    tableName.data.push(Arr);
  });
}

function getTableValues(paramData, carSelect) {
  Object.keys(tableData).forEach(tableName => {
    getUnitTableData(paramData, carSelect, tableData[tableName]);
  });
}

function getChartMonthValue(chartValue, carSelect, chartName, m, d) {
  if(d === "日期") return;
  let kpiCode = "";
  chartName.table.forEach(tableValue => {
    if(tableValue.title === d) {
      let index = carSelect.carKey;
      if(tableValue.kpiCodes.length < 5) index = 0; // 处理无车型选
      kpiCode = isAddAvg(tableValue, index);
    }
  });
  if(chartValue[kpiCode] === undefined) return null;
  if(m === "月平均") {
    return chartValue[kpiCode][0] === 0 ? null : chartValue[kpiCode][0];
  }
  return chartValue[kpiCode][m + 1] === 0 ? null : chartValue[kpiCode][m + 1]; // 返回 NULL 不显示数字
}

function getUnitChartData(chartValue, carSelect, chartName) {
  chartName.rows = [];
  const month = underscore.range(12);
  month.push("月平均");
  month.forEach(m => {
    let arr = {};
    chartName.columns.forEach(d => {
      if(m === "月平均") {
        arr["日期"] = m;
      } else {
        arr["日期"] = (m + 1) + "月";
      }
      arr[d] = getChartMonthValue(chartValue, carSelect, chartName, m, d);
    });
    chartName.rows.push(arr);
  });
}

function getChartRows(chartValue, carSelect) {
  Object.keys(chartData).forEach(chartName => {
    getUnitChartData(chartValue, carSelect, chartData[chartName]);
  });
}

export {
  areaData, carTypes, filterCodes, infoData, columnsData, columnsDataAgv, tableData, chartData, filterChartCodes,
  isAddAvg, getTableValues, getChartRows, getKpiCode, getTableDataCode,
};
